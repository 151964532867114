import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import styled from 'styled-components';

import AppBar1 from 'components/appBar/AppBar1';
import { useQuery } from '@apollo/client';
import BogleLoader from 'components/common/BogleLoader';
import { GetBoardsByTypeId, GetBoardsByTypeIdResponse } from 'services/graphql/Board';

const NoticeContainer = styled.div`
    display:flex;
    flex-direction: column;
    width: 100%;
`;

const NoticeTitle = styled.div`
    display:flex;
    padding: 32px 24px 0px; 24px;;

    // text
    font-family: 'Pretendard JP';
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 30px;
    display: flex;
    align-items: center;
    color: #2A2C32;
`;

const NoticeDate = styled.div`
    display:flex;
    padding: 0px 24px 20px 24px;
    hegith: 30px;

    // text
    font-family: 'Pretendard JP';
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 26px;
    display: flex;
    align-items: center;
    color: #777E88;
`;

const NoticeContent = styled.div`
    display:flex;
    padding: 0px 24px;

    // text 
    font-family: 'Pretendard JP';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    color: #777E88;
`;

const NoticeDetail: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();

    const { loading, error, data } = useQuery<GetBoardsByTypeIdResponse>(GetBoardsByTypeId, {
        variables: { boardTypeId: 6 }
    });

    if (loading) return <BogleLoader />;
    if (error) return null;

    const btnBackClick = () => {
        navigate(-1);
    };

    const formatDate = (isoString: string) => {
        const date = new Date(isoString);
        return `${date.getFullYear()}.${(date.getMonth() + 1).toString().padStart(2, '0')}.${date.getDate().toString().padStart(2, '0')}`;
    };

    return (
        <NoticeContainer>
            <AppBar1 backAction={btnBackClick}/>

            <NoticeTitle>{data?.getBoardsByTypeId[parseInt(id ?? '')].title}</NoticeTitle>
            <NoticeDate>{formatDate(data?.getBoardsByTypeId[parseInt(id ?? '')].createdAt ?? '')}</NoticeDate>
            <NoticeContent>{data?.getBoardsByTypeId[parseInt(id ?? '')].content}</NoticeContent>
        </NoticeContainer>
    );
};

export default NoticeDetail;
