import { gql } from '@apollo/client';
import { Board } from 'commons/types/types';

export interface GetBoardsByTypeIdResponse {
  getBoardsByTypeId: Board[];
}
  
export const GetBoardsByTypeId = gql`
  query getBoardsByTypeId($boardTypeId: Int!) {
    getBoardsByTypeId(boardTypeId: $boardTypeId) {
      id
      title
      content
      createdAt
      updatedAt
      hits
      userId
      boardTypeId
    }
  }
`;