import { gql } from '@apollo/client';
import { Qna } from 'commons/types/types';

export interface FindQnaResponse {
  findQNA: Qna[];
}

export const FIND_QNA = gql`
  query {
    findQNA {
      id
      question
      answer
    }
  }
`;