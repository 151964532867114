import React, { useState } from 'react';
import { ReactSVG } from 'react-svg';
import { Link, useNavigate } from 'react-router-dom';

import styled from 'styled-components';
import { useMutation } from '@apollo/client'; 

import BogleInput from 'components/common/BogleInput';
import BogleButton from 'components/common/BogleButton';
import BogleText from 'components/common/BogleText';
import BogleLogo from 'components/common/BogleLogo';
import BogleLoader from 'components/common/BogleLoader';
import { SIGNIN, SigninResponse } from 'services/graphql/User';
// import useKeyboardVisibility from 'hooks/UseKeyboardVisivility';

const LoginContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10rem;
  padding-bottom: 0.813rem;
`;

const LogoWapper = styled.div`
  svg path {
    fill: #8059BC;
  }
`;
const LogoTextWapper = styled.div`
  svg path {
    fill: #D5D9DD;
  }
`;

const BogleInputContainer = styled.div`
  display: flex;
  width: calc(100% - 48px);
  margin: 0px 24px;
  justify-content: center;
`;

const BogleInputWrapper = styled.div`
  width:100%;
  max-width: 20.875rem;
`;

const LinkContainer = styled.div`
  display: flex;
  width: calc(100% - 97px);
  max-width: 20.875rem;
  margin: 48.5px;
  margin-top: 32px;
  justify-content: space-evenly;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const BottomContainer = styled.div`
  display: flex;
  width: calc(100% - 82px);
  max-width: 20.875rem;
  justify-content: space-evenly;
  margin: 122px 41px 56px 41px;
`;

// const KeyBoardButton = styled.div<{$bottomBtnOk: boolean;}>`
//   display: flex;
//   width: 100%;
//   position: fixed;
//   bottom: 0px;
//   max-width: 20.875rem;
//   margin: 0px 0px 16px 0px;
//   background: ${({$bottomBtnOk}) => $bottomBtnOk === true ? '#A57BDB' : '#D5D9DD'};
//   border: none;
//   height: 3rem;
//   border-radius: 1rem;
//   align-items: center;
//   justify-content: center;

//   //text

//   font-family: 'Pretendard JP';
//   font-style: normal;
//   font-weight: 600;
//   font-size: 15px;
//   line-height: 3rem;
//   color: #FFFFFF;
// `;

const Login: React.FC = () => {
  const [pageLoading, setPageLoading] = useState(false);

  // const [bottomBtnVisible, setBottomBtnVisible] = useState(false);
  // const [bottomBtnOk, setBottomBtnOk] = useState(false);

  const [username, setUsername] = useState('');
  const [usernameState, setUsernameState] = useState<'normal' | 'typing' | 'success' | 'error'>('normal');
  const [usernameErrorMsg, setUsernameErrorMsg] = useState('');

  const [password, setPassword] = useState('');
  const [passwordState, setPasswordState] = useState<'normal' | 'typing' | 'success' | 'error'>('normal');
  const [passwordErrorMsg, setPasswordErrorMsg] = useState('');

  // useEffect(() => {
  //   setBottomBtnOk(email !== '' && password !== '');
  // },[email, password]);

  // const keyboardVisible = useKeyboardVisibility();
  const navigate = useNavigate();

  const [loginMutation] = useMutation<SigninResponse>(SIGNIN, {
    onCompleted: (data) => {
      // 로컬 스토리지에서 accessToken 추가
      localStorage.setItem('accessToken', data.signin.accessToken);

      // // 쿠키에서 refreshToken 추가
      document.cookie = `refreshToken=${data.signin.refreshToken}; HttpOnly; Secure; SameSite=Strict;`;

      setUsernameState('success');
      setPasswordState('success');

      setPageLoading(true);
      
      setTimeout(() => {
        navigate("/home");
      }, 2000);
    },
    onError: (error) => {
      console.error('Login failed:', error.message);

      setUsernameState('error');
      setUsernameErrorMsg(error.message);

      setPasswordState('error');
      setPasswordErrorMsg(error.message);
    },
  });

  const onClickedLogin = async () => {
    console.log(process.env.REACT_APP_API_URL);
    await loginMutation({ variables: { username: username, password: password } });
  };

  // const onClickedKeyBoardOk = async () => {
  //   onClickedLogin();
  // };

  return (
    <LoginContainer>
      {/* 상단 */}
      <HeaderContainer>
        <LogoWapper>
          <ReactSVG src='/images/logo/boogle-logo.svg' />
        </LogoWapper>
        <LogoTextWapper>
          <ReactSVG src='/images/logo/board-game-community-text.svg' />
        </LogoTextWapper>
      </HeaderContainer>

      {/* 메인 */}
      <BogleInputContainer>
        <BogleInputWrapper>
          <BogleInput
            state={usernameState}
            title="아이디"
            type="text"
            placeholder="아이디"
            value={username}
            errorText={usernameErrorMsg}
            onChange={(e) => setUsername(e.target.value)}
            // onFocus={() => setBottomBtnVisible(true)}
            // onBlur={() => setBottomBtnVisible(false)}
          />
          <BogleInput
            state={passwordState}
            type="password"
            title="비밀번호"
            placeholder="비밀번호"
            value={password}
            errorText={passwordErrorMsg}
            onChange={(e) => setPassword(e.target.value)}
            // onFocus={() => setBottomBtnVisible(true)}
            // onBlur={() => setBottomBtnVisible(false)}
          />
          <BogleButton
            height="3rem"
            $background="#A57BDB"
            radius="1rem"
            style={{
              padding: '0rem 1rem',
              gap: '8px',
              marginTop: '20px',
              justifyContent: 'center',
            }}
            onClick={onClickedLogin}
          >
            <BogleText color="#FFFFFF" fontSize="0.813rem">
              {'로그인'}
            </BogleText>
          </BogleButton>

        </BogleInputWrapper>
      </BogleInputContainer>
      <LinkContainer>
        <StyledLink to="/">
          <BogleText fontSize="0.813rem" width="57" height="20" color="#A9B1BC">
            {'아이디 찾기'}
          </BogleText>
        </StyledLink>
        <StyledLink to="/">
          <BogleText fontSize="0.813rem" width="57" height="20" color="#A9B1BC">
            {'비밀번호 찾기'}
          </BogleText>
        </StyledLink>
        <StyledLink to="/signup">
          <BogleText fontSize="0.813rem" width="57" height="20" color="#A9B1BC">
            {'회원가입'}
          </BogleText>
        </StyledLink>
      </LinkContainer>
      {/* 하단 */}
      <BottomContainer>
        <BogleLogo
          isHighlight={false}
          src="/images/login/kakao-logo.svg"
          alt="Kakao"
        />
        <BogleLogo
          isHighlight={true}
          src="/images/login/naver-logo.svg"
          alt="Naver"
        />
        <BogleLogo
          isHighlight={false}
          src="/images/login/apple-logo.svg"
          alt="Apple"
        />
      </BottomContainer>

      {pageLoading &&
        <BogleLoader />
      }

      {/* {keyboardVisible && bottomBtnVisible && 
        <KeyBoardButton $bottomBtnOk={bottomBtnOk} onClick={onClickedKeyBoardOk}>완료</KeyBoardButton> 
      } */}
    </LoginContainer>
  );
};

export default Login;
