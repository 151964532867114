import { gql } from '@apollo/client';

export interface SigninResponse {
  signin: {
    accessToken: string;
    refreshToken: string;
  };
}

export const SIGNIN = gql`
  mutation Signin(
    $username: String!,
    $password: String!
  ) {
    signin(
      data: {
        username: $username,
        password: $password,
        type: LOCAL 
      }
    ) {
        accessToken
        refreshToken
    }
  }
`;

export interface SignupResponse {
  signup: {
    accessToken: string;
    refreshToken: string;
  };
}

export const SIGNUP = gql`
  mutation Signup(
    $username: String!
    $email: String!
    $password: String!
    $nickname: String!
    $requireTerm: Boolean!
    $adConsentTerm: Boolean!
    $thirdPartyConsentTerm: Boolean!
  ) {
    signup(
      data: {
        username: $username
        email: $email
        password: $password
        nickname: $nickname
        requireTerm: $requireTerm
        adConsentTerm: $adConsentTerm
        thirdPartyConsentTerm: $thirdPartyConsentTerm
      }
    ) {
      accessToken
      refreshToken
    }
  }
`;